<template>
  <v-card class="elevation-12" :loading="loading">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>Авторизация</v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large to="/login" v-on="on">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>На главную</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon large @click.prevent="goToLink('register')" v-on="on">
            <v-icon>mdi-clipboard-account</v-icon>
          </v-btn>
        </template>
        <span>Регистрация</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="login"
          label="E-mail"
          name="login"
          prepend-icon="mdi-account"
          type="text"
        ></v-text-field>

        <v-text-field
          v-model="password"
          id="password"
          label="Пароль"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
        ></v-text-field>
        Нет аккаунта?
        <router-link to="/register">Зарегистрируйтесь</router-link>.<br />
        <router-link to="/askreset">Забыли пароль?</router-link>
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="sitekey"
          :loadRecaptchaScript="true"
          @verify="validate"
          @expired="onCaptchaExpired"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn to="/register" color="primary" v-on="on">Регистрация</v-btn>
        </template>
        <span>Еще нет учетной записи? Зарегистрируйтесь - это быстро!</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click.prevent="loginHandler"
        :loading="loading"
        :disabled="loading || !recaptchaToken"
        >Вход</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

const REST = process.env.VUE_APP_GRAPHQL;

export default {
  name: 'loginForm',
  components: { VueRecaptcha },
  data: () => ({
    login: '',
    password: '',
    loading: false,
    recaptchaToken: '',
    sitekey: '6Lf9RtoZAAAAAHKY0erJVy-QRdD5oVth5uvPCAcL'
  }),
  computed: {},
  methods: {
    ...mapMutations(['setMessageData']),

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = '';
    },
    validate(value) {
      this.recaptchaToken = value;
    },

    goToLink(link) {
      this.$router.push(link);
    },

    logoutHandler() {
      this.$store.commit('setLogout');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
      this.$router.push('/login');
    },

    loginHandler() {
      if (this.recaptchaToken) {
        const graphqlQuery = {
          query: `
          query UserLogin($email: String!, $password: String!, $recaptchaToken: String) {
            login(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
              token
              userId
              role
            }
          }
        `,
          variables: {
            email: this.login,
            password: this.password,
            recaptchaToken: this.recaptchaToken
          }
        };

        this.loading = true;

        fetch(REST, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(graphqlQuery)
        })
          .then(res => res.json())
          .then(resData => {
            this.loading = false;
            if (resData.errors && resData.errors[0].status === 422) {
              throw new Error('Ошибка валидации');
            }
            if (resData.errors) {
              this.recaptchaToken = '';
              this.$refs.recaptcha.reset();
              this.setMessageData({
                message: resData.errors[0].message,
                type: 'snackbar'
              });
              throw new Error('Ошибка при входе: ' + resData.errors[0].message);
            }
            this.$store.commit('setLogin', {
              token: resData.data.login.token,
              userId: resData.data.login.userId,
              role: resData.data.login.role
            });

            localStorage.setItem('token', resData.data.login.token);
            localStorage.setItem('userId', resData.data.login.userId);
            const remainingMilliseconds = 2 * 60 * 60 * 1000;
            const expiryDate = new Date(
              new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            this.setAutoLogout(remainingMilliseconds);

            this.$router.push('/');
          })
          .catch(error => {
            console.log(error);
            this.logoutHandler();
          });
      }
    },

    setAutoLogout(milliseconds) {
      setTimeout(() => {
        this.$store.commit('setLogout');
        localStorage.removeItem('token');
        localStorage.removeItem('expiryDate');
        localStorage.removeItem('userId');
        this.setMessageData({
          message:
            'Сеанс завершен из-за истечения времени активности. Пожалуйста войдите заново.',
          type: 'timeout'
        });
      }, milliseconds);
    }
  }
};
</script>

<style></style>
